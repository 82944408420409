import React, { useState, useEffect } from 'react';
import { Row, Card, Button, CardBody, CardTitle } from 'reactstrap';
import IntlMessages from 'helpers/IntlMessages';
import { Colxx, Separator } from 'components/common/CustomBootstrap';
import Breadcrumb from 'containers/navs/Breadcrumb';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { setContainerClassnames } from 'redux/actions';
import CourseService from 'services/CourseService';
import ReactQuill from 'react-quill';
import { ReactSortable } from 'react-sortablejs';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

import { updateLessonContent } from '../../redux/courses/actions';

const quillModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
      { header: [1, 2, 3] },
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const quillMessageModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['link'],
    // ['emoji'],
    // ['code-block', 'script'],
    // ['list', 'bullet'],
    // ['indent'],
    // ['align']
  ],
  clipboard: {
    matchVisual: false,
  },
};

const quillMessageFormats = [
  'bold',
  'italic',
  'underline',
  'strike',
  // 'code',
  'link',
  // 'spoiler',
  // 'emoji',
  // 'script',
  // 'bullet',
  // 'indent',
];

const EditContentMessageForm = ({
  params,
  item,
  save,
  index,
  product,
  isSelect,
  intl,
  textType,
  updateLessonContent,
}) => {
  const [textQuillStandart, setTextQuillStandart] = useState('');
  const [textQuillBubble, setTextQuillBubble] = useState('');

  return (
    <>
      {textType === 'message' ? (
        <ReactQuill
          theme="snow"
          value={textQuillBubble}
          onChange={(val) => setTextQuillBubble(val)}
          modules={quillMessageModules}
          formats={quillMessageFormats}
        />
      ) : (
        <ReactQuill
          theme="snow"
          value={textQuillStandart}
          onChange={(val) => setTextQuillStandart(val)}
          modules={quillModules}
          formats={quillFormats}
        />
      )}
      {/*       <Row className="mb-4">
        <Colxx xxs="12">
          <Card>
            <CardBody>
              <CardTitle>Lecture text</CardTitle>
              <ReactQuill
                theme="snow"
                value={textQuillStandart}
                onChange={(val) => setTextQuillStandart(val)}
                modules={quillModules}
                formats={quillFormats}
              />
            </CardBody>
          </Card>
        </Colxx>
      </Row> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedCourseId: state.courses.selectedCourseId,
    progressStatus: state.courses.status,
  };
};
const mapDispatchToProps = {
  updateLessonContent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditContentMessageForm);
