import { schema } from 'normalizr';

const content = new schema.Entity(
  'contents',
  {},
  {
    processStrategy: (value, parent, key) => {
      return { ...value, lesson: parent.id };
    },
  }
);

const lesson = new schema.Entity(
  'lessons',
  {
    contents: [content],
  },
  {
    processStrategy: (value, parent, key) => {
      return { ...value, course: parent.id };
    },
  }
);

const course = new schema.Entity('courses', {
  lessons: [lesson],
});

export default [course];
