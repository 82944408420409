import { $api } from '../helpers/api';

export default class AuthService {
  static async login(email, password) {
    return $api.post('auth/login', { email, password });
  }

  // Так и не разобрался почему работает только с дублем подтверждения пароля, дело в редуксе где-то
  static async registration(
    name,
    email,
    password,
    passwordConfirmation,
    passwordConfirmationTrue
  ) {
    return $api.post('auth/register', {
      name,
      email,
      password,
      password_confirmation: passwordConfirmationTrue,
    });
  }

  static async forgotPassword(email) {
    return $api.post('/user/forgot-password', { email });
  }

  static async resetPassword(token, email, password) {
    return $api.post('/user/reset-password', {
      token,
      email,
      password,
      password_confirmation: password,
    });
  }
}
