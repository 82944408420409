import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import courses from './courses/reducer';

import notifications from './notifications/reducer';
import entities from './entities/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  courses,
  // course,
  notifications,
  entities,
});

export default reducers;
