// eslint-disable-next-line import/named
import {
  ADD_COURSE,
  ADD_COURSE_ERROR,
  ADD_COURSE_SUCCESS,
  ADD_LESSON,
  ADD_LESSON_CONTENT,
  ADD_LESSON_CONTENT_ERROR,
  ADD_LESSON_CONTENT_SUCCESS,
  ADD_LESSON_ERROR,
  ADD_LESSON_SUCCESS,
  DELETE_COURSE,
  DELETE_COURSE_ERROR,
  DELETE_COURSE_SUCCESS,
  DELETE_LESSON,
  DELETE_LESSON_CONTENT,
  DELETE_LESSON_CONTENT_ERROR,
  DELETE_LESSON_CONTENT_SUCCESS,
  DELETE_LESSON_ERROR,
  DELETE_LESSON_SUCCESS,
  EDIT_COURSE,
  EDIT_COURSE_ERROR,
  EDIT_COURSE_SUCCESS,
  EDIT_LESSON,
  EDIT_LESSON_CONTENT,
  EDIT_LESSON_CONTENT_ERROR,
  EDIT_LESSON_CONTENT_SUCCESS,
  EDIT_LESSON_ERROR,
  EDIT_LESSON_SUCCESS,
  GET_COURSES,
  GET_COURSES_ERROR,
  GET_COURSES_SUCCESS,
  GOT_COURSE,
  PUBLISH_COURSE,
  PUBLISH_COURSE_ERROR,
  PUBLISH_COURSE_SUCCESS,
  SET_ACTIVE_LESSON,
  SET_ACTIVE_LESSON_CONTENT,
  SET_COURSE,
  UN_PUBLISH_COURSE,
  UN_PUBLISH_COURSE_ERROR,
  UN_PUBLISH_COURSE_SUCCESS,
} from '../contants';

const INIT_STATE = {
  loading: false,
  error: '',
  result: [],
  status: 'idle', // status: 'idle' | 'loading' | 'succeeded' | 'failed',
  selectedCourseId: null,
  selectedLessonId: null,
  selectedContentId: null,
  selectedLessonEditing: null,
  selectedLessonContentEditing: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COURSES:
      return {
        ...state,
        loading: true,
        status: 'loading',
      };
    case GET_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        status: 'succeeded',
      };
    case GET_COURSES_ERROR:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    case ADD_COURSE:
      return {
        ...state,
        loading: true,
        status: 'loading',
      };
    case ADD_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        status: 'course-added-succeeded',
      };
    case ADD_COURSE_ERROR:
      return {
        ...state,
        loading: false,
        status: 'failed',
      };
    case EDIT_COURSE:
      return {
        ...state,
        loading: true,
        status: 'loading',
      };
    case EDIT_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        status: 'course-added-succeeded',
        message: 'test',
      };
    case EDIT_COURSE_ERROR:
      return {
        ...state,
        loading: false,
        status: 'failed',
      };
    case PUBLISH_COURSE:
      return {
        ...state,
        loading: true,
        status: 'loading',
      };
    case PUBLISH_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'succeeded',
      };
    case PUBLISH_COURSE_ERROR:
      return {
        ...state,
        loading: false,
        status: 'failed',
      };
    case UN_PUBLISH_COURSE:
      return {
        ...state,
        loading: true,
        status: 'loading',
      };
    case UN_PUBLISH_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'succeeded',
      };
    case UN_PUBLISH_COURSE_ERROR:
      return {
        ...state,
        loading: false,
        status: 'failed',
      };
    case DELETE_COURSE:
      return {
        ...state,
        loading: true,
        status: 'loading',
      };
    case DELETE_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'succeeded',
      };
    case DELETE_COURSE_ERROR:
      return {
        ...state,
        loading: false,
        status: 'failed',
      };
    case SET_COURSE:
      const { courseId } = action;
      return {
        ...state,
        selectedCourseId: courseId,
      };
    case SET_ACTIVE_LESSON:
      return {
        ...state,
        selectedLessonId: action.lessonId,
      };
    case SET_ACTIVE_LESSON_CONTENT:
      return {
        ...state,
        selectedContentId: action.lessonContentId,
      };
    case ADD_LESSON:
      return {
        ...state,
        loading: false,
        status: 'add-lesson-loading',
      };
    case ADD_LESSON_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        status: 'succeeded',
      };
    case ADD_LESSON_ERROR:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        status: 'failed',
      };
    case EDIT_LESSON:
      return {
        ...state,
        loading: false,
        status: 'loading',
        selectedLessonEditing: action.payload.lessonId,
      };
    case EDIT_LESSON_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        status: 'succeeded',
        selectedLessonEditing: null,
      };
    case EDIT_LESSON_ERROR:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        status: 'failed',
        selectedLessonEditing: null,
      };
    case DELETE_LESSON:
      return {
        ...state,
        loading: false,
        status: 'loading',
        selectedLessonEditing: action.payload.lessonId,
      };
    case DELETE_LESSON_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        status: 'succeeded',
        selectedLessonEditing: null,
      };
    case DELETE_LESSON_ERROR:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        status: 'failed',
        selectedLessonEditing: null,
      };
    case ADD_LESSON_CONTENT:
      return {
        ...state,
        loading: false,
        status: 'add-lesson-content-loading',
        selectedLessonEditing: action.payload.lessonId,
      };
    case ADD_LESSON_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        status: 'succeeded',
        selectedLessonEditing: null,
      };
    case ADD_LESSON_CONTENT_ERROR:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        status: 'failed',
        selectedLessonEditing: null,
      };
    case EDIT_LESSON_CONTENT:
      return {
        ...state,
        loading: false,
        status: 'loading',
        selectedLessonEditing: action.payload.lessonId,
        selectedLessonContentEditing: action.payload.contentId,
      };
    case EDIT_LESSON_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        status: 'succeeded',
        selectedLessonEditing: null,
        selectedLessonContentEditing: null,
      };
    case EDIT_LESSON_CONTENT_ERROR:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        status: 'failed',
        selectedLessonEditing: null,
        selectedLessonContentEditing: null,
      };
    case DELETE_LESSON_CONTENT:
      return {
        ...state,
        loading: false,
        status: 'loading',
        selectedLessonEditing: action.payload.lessonId,
        selectedLessonContentEditing: action.payload.contentId,
      };
    case DELETE_LESSON_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        status: 'succeeded',
        selectedLessonEditing: null,
        selectedLessonContentEditing: null,
      };
    case DELETE_LESSON_CONTENT_ERROR:
      return {
        ...state,
        loading: false,
        result: action.payload.result,
        status: 'failed',
        selectedLessonEditing: null,
        selectedLessonContentEditing: null,
      };
    case GOT_COURSE:
      return {
        ...state,
        loading: false,
        selectedCourseId: action.payload.result.id,
        status: 'succeeded',
      };
    default:
      return {
        ...state,
        loading: true,
      };
  }
};

export const getAllCourses = (state) => Object.values(state.entities.courses);

// export const getActiveCourse = (state) => state.courses.entities.courses[state.courses.selectedCourseId];

export const getActiveCourse = (state) => {
  if (state.courses.selectedCourseId) {
    return state.entities.courses[state.courses.selectedCourseId];
  }
};

export const getCourseById = (state, courseId) => {
  if (!state.entities.courses) {
    return null;
  }
  return state.entities.courses[courseId];
};

export const getLessonById = (state, lessonId) => {
  if (!state.entities.lessons) {
    return null;
  }
  return state.entities.lessons[lessonId];
};

export const getActiveCourseLessons = (state) =>
  state.entities.courses[state.courses.selectedCourseId].lessons.map(
    (id) => state.entities.lessons[id]
  );
export const getContentsByLessonsId = (state, lessonId) => {
  return state.entities.lessons[lessonId].contents.map(
    (id) => state.entities.contents[id]
  );
};
export const getContentsByIds = (state, contentIdList) =>
  contentIdList.map((id) => state.entities.contents[id]);
