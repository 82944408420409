import { $api } from '../helpers/api';

export default class CourseService {
  static async createCourse(name, token) {
    return $api.post('/bots', { name, token });
  }

  static async getCourses() {
    return $api.get('/bots', {});
  }

  static async getCourse(id) {
    return $api.get(`/bots/${id}`, {});
  }

  static async updateCourse(id, data) {
    return $api.put(`/bots/${id}`, data);
  }

  static async deleteCourses(id) {
    return $api.delete(`/bots/${id}`, {});
  }

  static async publishCourse(id) {
    return $api.post(`/bots/${id}/run/activate`, {});
  }

  static async unpublishCourse(id) {
    return $api.post(`/bots/${id}/run/deactivate`, {});
  }
}
