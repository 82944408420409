import lesson from 'views/app/courses/course/lesson/lesson';
import { $api } from '../helpers/api';

export default class LessonService {
  static async getLessons(courseId) {
    return $api.get(`/bots/${courseId}}/lessons`, {});
  }

  static async getLesson(courseId, lessonId) {
    return $api.get(`/bots/${courseId}}/lessons/${lessonId}`, {});
  }

  static async deleteLesson(courseId, lessonId) {
    return $api.delete(`/bots/${courseId}/lessons/${lessonId}`, {});
  }

  static async createLesson(courseId, dayType) {
    return $api.post(`/bots/${courseId}/lessons`, { day_type: dayType });
  }

  static async updateLessonDay(courseId, lessonId, day) {
    return $api.put(`/bots/${courseId}/lessons/${lessonId}`, {
      day_number: day,
    });
  }

  static async updateLessonContent(courseId, lessonId, list) {
    return $api.put(`/bots/${courseId}/lessons/${lessonId}`, { content: list });
  }

  static async updateLesson(courseId, lessonId, data) {
    return $api.put(`/bots/${courseId}/lessons/${lessonId}`, data);
  }
}
