import { schema } from 'normalizr';
import contentSchema from './content';
import courseSchema from './course';

// const lessonSchema = new schema.Entity('lessons');
//
// lessonSchema.define({
//   // course: courseSchema,
//   contents: [contentSchema],
// });

const lessonSchema = new schema.Entity(
  'lessons',
  {
    contents: [contentSchema],
  },
  {
    processStrategy: (value, parent, key) => {
      return { ...value, course: parent.id };
    },
  }
);

export default lessonSchema;

// const lessonProcessStrategy = (value, parentObj) => {
//   switch (parentObj.type) {
//     case 'parent': {
//       return {...value, parents: [parentObj.id]}
//     }
//     default:
//       return value
//   }
// };
//
//   const lessonMergeStrategy = (objA, objB) => ({
//     ...objA,
//     ...objB,
//     parents: [...(objA.parents || []), ...(objB.parents || [])]
//   });
