import {
  ADD_COURSE,
  ADD_LESSON,
  ADD_LESSON_CONTENT,
  ADD_LESSON_ERROR,
  ADD_LESSON_SUCCESS,
  DELETE_LESSON,
  DELETE_LESSON_CONTENT,
  EDIT_COURSE,
  EDIT_LESSON,
  EDIT_LESSON_CONTENT,
  GET_COURSES,
  SET_ACTIVE_LESSON,
  SET_ACTIVE_LESSON_CONTENT,
  SET_COURSE,
} from '../contants';

export const getCourses = () => {
  return {
    type: GET_COURSES,
  };
};

export function setCourse(courseId) {
  return {
    type: SET_COURSE,
    courseId,
  };
}

export const addNewCourse = (name, token) => {
  return {
    type: ADD_COURSE,
    payload: { name, token },
  };
};
export const editCourse = (courseId, data) => {
  return {
    type: EDIT_COURSE,
    payload: { courseId, data },
  };
};

export function setActiveLesson(lessonId) {
  return {
    type: SET_ACTIVE_LESSON,
    lessonId,
  };
}
export function setActiveLessonContent(lessonContentId) {
  return {
    type: SET_ACTIVE_LESSON_CONTENT,
    lessonContentId,
  };
}

export const addNewLesson = (courseId, dayType) => {
  return {
    type: ADD_LESSON,
    payload: { courseId, dayType },
  };
};
export const editLesson = (courseId, lessonId, data) => {
  return {
    type: EDIT_LESSON,
    payload: { courseId, lessonId, data },
  };
};
export const deleteLesson = (courseId, lessonId) => {
  return {
    type: DELETE_LESSON,
    payload: { courseId, lessonId },
  };
};
export const addLessonSuccess = (courseId, history) => {
  return {
    type: ADD_LESSON_SUCCESS,
    payload: { courseId, history },
  };
};
export const addLessonError = (courseId, history) => {
  return {
    type: ADD_LESSON_ERROR,
    payload: { courseId, history },
  };
};

export const addLessonContent = (courseId, lessonId, contentType, data) => {
  return {
    type: ADD_LESSON_CONTENT,
    payload: { courseId, lessonId, contentType, data },
  };
};
export const updateLessonContent = (courseId, lessonId, contentId, data) => {
  return {
    type: EDIT_LESSON_CONTENT,
    payload: { courseId, lessonId, contentId, data },
  };
};
export const deleteLessonContent = (courseId, lessonId, contentId) => {
  return {
    type: DELETE_LESSON_CONTENT,
    payload: { courseId, lessonId, contentId },
  };
};
