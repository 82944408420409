import React, { useState, useEffect, useRef } from 'react';
import IntlMessages from 'helpers/IntlMessages';
import { Button, Row } from 'reactstrap';
import { Colxx, Separator } from 'components/common/CustomBootstrap';

import { ReactSortable } from 'react-sortablejs';
import LessonSideBar from 'components/lesson/LessonSideBar';
import Info from 'components/day/Info';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getContentsByLessonsId,
  getLessonById,
} from '../../../../../redux/courses/reducer';
import { setContainerClassnames } from '../../../../../redux/menu/actions';
import {
  setActiveLesson,
  updateLessonContent,
} from '../../../../../redux/courses/actions';
import LessonContentFull from '../../../../../components/lesson/LessonContentFull';

const Lesson = ({
  lesson,
  match,
  setActiveLessonAction,
  contents,
  progressStatus,
  selectedCourseId,
  updateLessonContent,
}) => {
  const [isLoaded, setIsLoaded] = useState(true);
  const [contentsList, setContentsList] = useState(contents);
  const lessonId = match.params.id;
  const history = useHistory();
  const scrollToRef = useRef();
  useEffect(() => {
    setActiveLessonAction(lessonId);
    setContainerClassnames(0, 'menu-default', true);
  }, [setActiveLessonAction]);
  useEffect(() => {
    if (progressStatus === 'succeeded') {
      // const sortedContentsListByPosition = contents.sort((b, a) => {
      //   return b.position - a.position;
      // });
      setContentsList(contents);
    }
    if (progressStatus === 'add-lesson-content-loading') {
      scrollToRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [progressStatus]);
  // eslint-disable-next-line consistent-return
  const handleUpdateLessonContent = (evt) => {
    if (progressStatus === 'loading') return false;
    const oldItem = contents[evt.oldIndex];
    const newPosition = evt.newIndex + 1;
    updateLessonContent(selectedCourseId, oldItem.lesson_id, oldItem.id, {
      position: newPosition,
    });
  };
  return !isLoaded ? (
    <div className="loading" />
  ) : (
    <>
      <Row>
        <Colxx xxs="12">
          <Button
            onClick={history.goBack}
            color="light"
            size="lg"
            className="top-left-button mb-5 glyph-icon iconsminds-left"
          >
            Back to curriculum
          </Button>
          <Separator className="mb-5" />
        </Colxx>
      </Row>
      <Info>
        <h2 className="mb-0 list-item-heading">
          <IntlMessages id="lesson.help" />
        </h2>
      </Info>
      <Row>
        <LessonSideBar lessonId={lesson.id} lessonType={lesson.day_type} />
        <Colxx className="mb-4 col-right col-12 col-lg-8 col-xxl-10 col-xl-9">
          {contents.length === 0 ? (
            ' No contents. Please add.'
          ) : (
            <ReactSortable
              filter=".addImageButtonContainer"
              dragClass="sortableDrag"
              animation="200"
              easing="ease-out"
              className="sortable-grab"
              list={contentsList}
              setList={setContentsList}
              handle=".lesson-content-type-card-full-item-drag"
              // onUpdate={(evt) => {
              //   handleUpdateLessonContent(evt);
              // }}
            >
              {contentsList.map((content) => (
                <LessonContentFull contentData={content} key={content.id} />
              ))}
              {/* {contents.map((content) => { */}
              {/*  return ( */}
              {/*    <div key={content.id}> */}
              {/*      <LessonContentFull contentData={content} key={content.id} /> */}
              {/*    </div> */}
              {/*  ); */}
              {/* })} */}
            </ReactSortable>
          )}
          {progressStatus === 'add-lesson-content-loading' && (
            <div
              ref={scrollToRef}
              className="lesson-content-type-card-full-item is-type-message lesson-content-type-card-full-item--is-new-loading"
            >
              <div className="lesson-card-loading">
                <div className="loading-icon" />
              </div>
            </div>
          )}
        </Colxx>
      </Row>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log('Почему вызывается два раза?');
  console.log('state.events',state);
  return {
    // course: getActiveCourse(state),
    lesson: getLessonById(state, ownProps.match.params.id),
    contents: getContentsByLessonsId(state, ownProps.match.params.id),
    selectedCourseId: state.courses.selectedCourseId,
    progressStatus: state.courses.status,
  };
};

const mapDispatchToProps = {
  setActiveLessonAction: setActiveLesson,
  updateLessonContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Lesson);
