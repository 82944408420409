import React from 'react';

import { connect } from 'react-redux';
import { addLessonContent } from '../../redux/courses/actions';

const DuplicateContentButton = ({
  contentDataCopyFrom,
  selectedCourseId,
  addLessonContent,
}) => {
  const duplicateLessonContentSubmit = () => {
    if (
      contentDataCopyFrom.hasOwnProperty('content') &&
      contentDataCopyFrom.content !== ''
    ) {
      addLessonContent(
        selectedCourseId,
        contentDataCopyFrom.lesson_id,
        contentDataCopyFrom.type,
        {
          name: `Copy of ${contentDataCopyFrom.name}`,
          content: contentDataCopyFrom.content,
        }
      );
    } else {
      addLessonContent(
        selectedCourseId,
        contentDataCopyFrom.lesson_id,
        contentDataCopyFrom.type,
        {
          name: `Copy of ${contentDataCopyFrom.name}`,
        }
      );
    }
  };
  return (
    <button
      type="button"
      className="btn"
      onClick={() => duplicateLessonContentSubmit()}
    >
      <span className="iconsminds-files" />
    </button>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedCourseId: state.courses.selectedCourseId,
    progressStatus: state.courses.status,
  };
};

const mapDispatchToProps = {
  addLessonContent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DuplicateContentButton);
