/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_USER_ERROR = 'RESET_USER_ERROR';
/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* Courses */
export const GET_COURSES = 'GET_COURSES';
export const GET_COURSES_SUCCESS = 'GOT_COURSES_SUCCESS';
export const GET_COURSES_ERROR = 'GOT_COURSES_ERROR';

/* Course */
export const SET_COURSE = 'SET_COURSE';

export const GET_COURSE = 'GET_COURSE';
export const GOT_COURSE = 'GOT_COURSE';
export const GET_COURSE_ERROR = 'GET_COURSE_ERROR';

export const ADD_COURSE = 'ADD_COURSE';
export const ADD_COURSE_SUCCESS = 'ADD_COURSE_SUCCESS';
export const ADD_COURSE_ERROR = 'ADD_COURSE_ERROR';

export const EDIT_COURSE = 'EDIT_COURSE_COURSE';
export const EDIT_COURSE_SUCCESS = 'EDIT_COURSE_SUCCESS';
export const EDIT_COURSE_ERROR = 'EDIT_COURSE_ERROR';

export const PUBLISH_COURSE = 'PUBLISH_COURSE';
export const PUBLISH_COURSE_SUCCESS = 'PUBLISH_COURSE_SUCCESS';
export const PUBLISH_COURSE_ERROR = 'PUBLISH_COURSE_ERROR';

export const UN_PUBLISH_COURSE = 'UN_PUBLISH_COURSE';
export const UN_PUBLISH_COURSE_SUCCESS = 'UN_PUBLISH_COURSE_SUCCESS';
export const UN_PUBLISH_COURSE_ERROR = 'UN_PUBLISH_COURSE_ERROR';

export const DELETE_COURSE = 'DELETE_COURSE';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';
export const DELETE_COURSE_ERROR = 'DELETE_COURSE_ERROR';
// export const COURSES_CHANGE_COURSE_NAME = 'COURSES_CHANGE_COURSE_NAME';

/* Notifications */
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';

/* Lessons */
export const SET_ACTIVE_LESSON = 'SET_ACTIVE_LESSON';
export const ADD_LESSON = 'ADD_LESSON';
export const ADD_LESSON_SUCCESS = 'ADD_LESSON_SUCCESS';
export const ADD_LESSON_ERROR = 'ADD_LESSON_ERROR';
export const ADD_LESSON_CONTENT = 'ADD_LESSON_CONTENT';
export const ADD_LESSON_CONTENT_SUCCESS = 'ADD_LESSON_CONTENT_SUCCESS';
export const ADD_LESSON_CONTENT_ERROR = 'ADD_LESSON_CONTENT_ERROR';
export const EDIT_LESSON = 'EDIT_LESSON';
export const EDIT_LESSON_SUCCESS = 'EDIT_LESSON_SUCCESS';
export const EDIT_LESSON_ERROR = 'EDIT_LESSON_ERROR';
export const EDIT_LESSON_CONTENT = 'EDIT_LESSON_CONTENT';
export const EDIT_LESSON_CONTENT_SUCCESS = 'EDIT_LESSON_CONTENT_SUCCESS';
export const EDIT_LESSON_CONTENT_ERROR = 'EDIT_LESSON_CONTENT_ERROR';
export const DELETE_LESSON = 'DELETE_LESSON';
export const DELETE_LESSON_SUCCESS = 'DELETE_LESSON_SUCCESS';
export const DELETE_LESSON_ERROR = 'DELETE_LESSON_ERROR';
export const DELETE_LESSON_CONTENT = 'DELETE_LESSON_CONTENT';
export const DELETE_LESSON_CONTENT_SUCCESS = 'DELETE_LESSON_CONTENT_SUCCESS';
export const DELETE_LESSON_CONTENT_ERROR = 'DELETE_LESSON_CONTENT_ERROR';
export const SET_ACTIVE_LESSON_CONTENT = 'SET_ACTIVE_LESSON_CONTENT';
/* Entities */
export const ADD_ENTITIES = 'ADD_ENTITIES';
