import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import EditContentMessageModal from './EditContentMessageModal';

const EditContentMessageControl = ({ contentData }) => {
  const [modalOpen, setModalOpen] = useState(false);

  function $_GET() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const contentIdEdit = $_GET().get('contentIdEdit');

  useEffect(() => {
    if (contentIdEdit && contentIdEdit == contentData.id) {
      setModalOpen(true);
    }
  }, []);

  const toggle = () => setModalOpen(!modalOpen);
  return (
    <>
      <button type="button" className="btn" onClick={toggle}>
        <span className="iconsminds-file-edit" />
      </button>
      <EditContentMessageModal
        courseId="asd"
        modalOpen={modalOpen}
        toggleModal={toggle}
        contentData={contentData}
      />
    </>
  );
};
export default EditContentMessageControl;
