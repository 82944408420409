// eslint-disable-next-line import/named
import {
  DELETE_COURSE,
  DELETE_COURSE_ERROR,
  DELETE_COURSE_SUCCESS,
  GET_COURSE,
  GET_COURSE_ERROR,
  PUBLISH_COURSE,
  PUBLISH_COURSE_ERROR,
  PUBLISH_COURSE_SUCCESS,
  UN_PUBLISH_COURSE,
  UN_PUBLISH_COURSE_ERROR,
  UN_PUBLISH_COURSE_SUCCESS,
  ADD_NEW_LESSON,
  LESSON_ADDED_SUCCESS,
  LESSON_ADDED_ERROR,
} from '../contants';

// eslint-disable-next-line import/prefer-default-export
export const getCourse = (courseId, history) => {
  return {
    type: GET_COURSE,
    payload: { courseId, history },
  };
};

export const getCourseError = (message) => {
  return {
    type: GET_COURSE_ERROR,
    payload: { error: message },
  };
};
export const publishCourse = (id) => {
  return {
    type: PUBLISH_COURSE,
    payload: id,
  };
};
export const unPublishCourse = (id) => {
  return {
    type: UN_PUBLISH_COURSE,
    payload: id,
  };
};
export const deleteCourse = (courseId, history) => {
  return {
    type: DELETE_COURSE,
    payload: { courseId, history },
  };
};
export const publishCourseSuccess = (message, courseId) => {
  return {
    type: PUBLISH_COURSE_SUCCESS,
    payload: { message, courseId },
  };
};
export const publishCourseError = (message) => {
  return {
    type: PUBLISH_COURSE_ERROR,
    payload: { error: message },
  };
};
export const unPublishCourseSuccess = (message, courseId) => {
  return {
    type: UN_PUBLISH_COURSE_SUCCESS,
    payload: { message, courseId },
  };
};
export const unPublishCourseError = (message) => {
  return {
    type: UN_PUBLISH_COURSE_ERROR,
    payload: { error: message },
  };
};

export const deleteCourseSuccess = (message, courseId) => {
  return {
    type: DELETE_COURSE_SUCCESS,
    payload: { message, courseId },
  };
};

export const deleteCourseError = (message) => {
  return {
    type: DELETE_COURSE_ERROR,
    payload: { error: message },
  };
};
