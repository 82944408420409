/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import IntlMessages from 'helpers/IntlMessages';
import { adminRoot } from 'constants/defaultValues';

const getMenuTitle = (sub, courseName) => {
  if (`/${sub}` === adminRoot) return <IntlMessages id="menu.home" />;
  if (/\d/.test(`/${sub}`)) {
    return courseName; // <IntlMessages id="menu.course" />;
  }
  return <IntlMessages id={`menu.${sub}`} />;
};

const getUrl = (path, sub) => {
  return path.split(sub)[0] + sub;
};

const BreadcrumbContainer = ({ heading, match, courseName }) => {
  return (
    <>
      {heading && (
        <h1>
          <IntlMessages id={heading} />
        </h1>
      )}
      <BreadcrumbItems match={match} courseName={courseName} />
    </>
  );
};

const BreadcrumbItems = ({ match, courseName }) => {
  const path = match.path.substr(1);
  let paths = path.split('/');
  if (paths[paths.length - 1].indexOf(':') > -1) {
    paths = paths.filter((x) => x.indexOf(':') === -1);
  }
  return (
    <>
      <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
        {paths.map((sub, index) => {
          if (`/${getUrl(path, sub, index)}` === '/app/course') {
          } else
            return (
              <BreadcrumbItem key={index} active={paths.length === index + 1}>
                {paths.length !== index + 1 ? (
                  <NavLink to={`/${getUrl(path, sub, index)}`}>
                    {getMenuTitle(sub, courseName)}
                  </NavLink>
                ) : (
                  getMenuTitle(sub, courseName)
                )}
              </BreadcrumbItem>
            );
        })}
      </Breadcrumb>
    </>
  );
};

export default BreadcrumbContainer;
