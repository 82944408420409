import React from 'react';
import { Button, CardBody, Row } from 'reactstrap';
import IntlMessages from 'helpers/IntlMessages';
import { Colxx } from 'components/common/CustomBootstrap';
import { connect } from 'react-redux';
import { addLessonContent } from '../../redux/courses/actions';

const LessonSideBar = ({
  lessonType,
  lessonId,
  selectedCourseId,
  progressStatus,
  addLessonContent,
}) => {
  const addLessonContentMessageType = () => {
    if (progressStatus === 'loading') return false;
    addLessonContent(selectedCourseId, lessonId, 'message');
  };
  const addLessonContentLectureType = () => {
    if (progressStatus === 'loading') return false;
    addLessonContent(selectedCourseId, lessonId, 'lecture');
  };
  return (
    <>
      <Colxx className="col-left col-12 col-lg-4 col-xl-3 col-xxl-2 col">
        <div className="fix-cont">
          <CardBody className="lesson-edit-sidebar-actions">
            <Row
              className="lesson-content-item__buttons"
              style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'unset',
              }}
            >
              {lessonType === 'lesson' && (
                <div
                  className="btn lesson-content-item-button lesson-content-item-button__color-green"
                  key={1}
                  onClick={() => addLessonContentLectureType()}
                >
                  <div className="icon glyph-icon simple-icon-plus" />
                  Add new lecture
                </div>
              )}
              <div
                className="btn lesson-content-item-button lesson-content-item-button__color-blue"
                key={2}
                onClick={() => addLessonContentMessageType()}
              >
                <div className="icon glyph-icon simple-icon-plus" />
                Add new message
              </div>
            </Row>
          </CardBody>
        </div>
      </Colxx>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedCourseId: state.courses.selectedCourseId,
    progressStatus: state.courses.status,
  };
};
const mapDispatchToProps = {
  addLessonContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonSideBar);
