import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

$api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// $api.interceptors.response.use((res) => {
//   // console.log(res);
//   return res;
// });

// $api.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (error) => {
//     const originalConfig = error.config;
//
//     if (originalConfig.url !== '/auth/login' && error.response) {
//       // Access Token was expired
//       // if (error.response.status === 401) {
//       //   window.location = '/user/login';
//       // }
//       // if (
//       //   error.response.status === 401 &&
//       //   error.config &&
//       //   !error.config._isRetry
//       // ) {
//       //   originalConfig._retry = true;
//       //   try {
//       //     const response = await $api.get('/auth/refresh', {
//       //       withCredentials: true,
//       //     });
//       //
//       //     console.log(response);
//       //     localStorage.setItem('token', response.data.access_token);
//       //
//       //     return $api(originalConfig);
//       //   } catch (_error) {
//       //     return Promise.reject(_error);
//       //   }
//       // }
//     }
//     return Promise.reject(err);
//   }
// );
// $api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   function (error) {
//     const originalRequest = error.config;
//
//     if (
//       error.response.status === 401 &&
//       originalRequest.url === `${API_URL}auth/refresh`
//     ) {
//       router.push('/login');
//       return Promise.reject(error);
//     }
//
//     if (
//       error.response.status === 401 &&
//       error.config &&
//       !error.config._isRetry
//     ) {
//       originalRequest._retry = true;
//       return $api
//         .get(`${API_URL}auth/refresh`, { withCredentials: true })
//         .then((res) => {
//           if (res.status === 201) {
//             localStorage.setItem('token', res.data.access_token);
//             return $api.request(originalRequest);
//           }
//         })
//         .then((error) => {
//           console.log(error);
//           console.log('No autorize');
//         });
//     }
//     return Promise.reject(error);
//   }
// );
export { $api, API_URL };
