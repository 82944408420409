import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { adminRoot, currentUser } from 'constants/defaultValues';
import { setCurrentUser } from 'helpers/Utils';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../contants';

import AuthService from '../../services/AuthService';
import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await AuthService.login(email, password)
    .then((user) => user)
    // eslint-disable-next-line func-names
    .catch(function (error) {
      return Promise.reject(error);
    });

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (loginUser.data) {
      const item = {
        accessToken: loginUser.data.access_token,
        user: loginUser.data.user,
        ...currentUser,
      };
      localStorage.setItem('token', loginUser.data.access_token);
      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
    }
  } catch (error) {
    console.log(error,'error login')
    if (error.response.data.errors) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < error.response.data.errors.length; i++) {
        yield put(loginUserError(error.response.data.errors[i]));
      }
    } else {
      yield put(
        loginUserError('Error. Try again, please, or message to support.')
      );
    }
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (
  name,
  email,
  password,
  passwordConfirmation
) => {
  // eslint-disable-next-line no-return-await
  return await AuthService.registration(
    name,
    email,
    password,
    password,
    passwordConfirmation
  )
    .then((user) => user)
    // eslint-disable-next-line func-names
    .catch(function (error) {
      return Promise.reject(error);
    });
};

function* registerWithEmailPassword({ payload }) {
  const { name, email, password, passwordConfirmation } = payload.user;
  const { history } = payload;

  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      name,
      email,
      password,
      passwordConfirmation
    );
    if (registerUser.data) {
      const item = {
        accessToken: registerUser.data.access_token,
        user: registerUser.data.user,
        ...currentUser,
      };
      localStorage.setItem('token', registerUser.data.access_token);
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    }
  } catch (error) {
    if (error.response.data.errors) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < error.response.data.errors.length; i++) {
        yield put(registerUserError(error.response.data.errors[i]));
      }
    } else {
      yield put(
        registerUserError('Error. Try again, please, or message to support.')
      );
    }
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return await AuthService.forgotPassword(email)
    .then((responce) => responce)
    // eslint-disable-next-line func-names
    .catch(function (error) {
      return Promise.reject(error);
    });
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (forgotPasswordStatus.data.status) {
      yield put(forgotPasswordSuccess('success'));
    }
  } catch (error) {
    if (error.response.data.errors) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < error.response.data.errors.length; i++) {
        yield put(registerUserError(error.response.data.errors[i]));
      }
    } else {
      yield put(forgotPasswordError(error));
    }
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (token, email, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await AuthService.resetPassword(token, email, newPassword)
    .then((user) => user)
    // eslint-disable-next-line func-names
    .catch(function (error) {
      return Promise.reject(error);
    });
};

function* resetPassword({ payload }) {
  const { token, email, newPassword } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      token,
      email,
      newPassword
    );
    if (resetPasswordStatus.data.message) {
      yield put(resetPasswordSuccess('success'));
    }
  } catch (error) {
    if (error.response.data.errors) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < error.response.data.errors.length; i++) {
        yield put(resetPasswordError(error.response.data.errors[i]));
      }
    } else {
      yield put(resetPasswordError(error));
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
