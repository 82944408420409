import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import EditContentMessageForm from './EditContentMessageForm';

import { updateLessonContent } from '../../redux/courses/actions';
import IntlMessages from '../../helpers/IntlMessages';

const quillModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
      { header: [1, 2, 3] },
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];



const quillMessageModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['link'],
    // ['emoji'],
    // ['code-block', 'script'],
    // ['list', 'bullet'],
    // ['indent'],
    // ['align']
  ],
  clipboard: {
    matchVisual: false,
  },
};

const quillMessageFormats = [
  'bold',
  'italic',
  'underline',
  'strike',
  // 'code',
  'link',
  // 'spoiler',
  // 'emoji',
  // 'script',
  // 'bullet',
  // 'indent',
];

const EditContentMessageModal = ({
  modalOpen,
  toggleModal,
  contentData,
  updateLessonContent,
  progressStatus,
  selectedCourseId,
  selectedLessonContentEditing,
}) => {
  const [content, setContent] = useState(contentData.content);

  function handleChange(value) {
    const text = value.replaceAll(/<\/?p[^>]*>/g, '').replace('<br>', '');
    setContent(text);
  }

  useEffect(() => {
    if (progressStatus === 'succeeded' && modalOpen) {
      toggleModal();
    }
  }, [progressStatus]);

  const saveContent = (e) => {
    if (progressStatus === 'loading') return false;
    updateLessonContent(
      selectedCourseId,
      contentData.lesson_id,
      contentData.id,
      { content }
    );
  };
  const saveAndPublish = () => {
    if (progressStatus === 'loading') return false;
    updateLessonContent(
      selectedCourseId,
      contentData.lesson_id,
      contentData.id,
      { content, is_published: 1 }
    );
  };
  return (
    <Modal
      isOpen={modalOpen}
      toggle={toggleModal}
      backdrop="static"
      className="lesson-contents-edit-modal"
      size="lg"
    >
      <ModalHeader>
        Edit {contentData.type} - {contentData.name}
        {(contentData.is_published === 0 || !contentData.is_published) && (
          <div className="lesson-content-type-card-full-item__status badge badge-light">
            Is draft
          </div>
        )}
      </ModalHeader>
      <ModalBody>
        {contentData.type === 'message' ? (
          <ReactQuill
            value={content}
            onChange={(val) => setContent(val)}
            modules={quillMessageModules}
            formats={quillMessageFormats}
          />
        ) : (
          <ReactQuill
            theme="snow"
            value={content}
            onChange={(val) => setContent(val)}
            modules={quillModules}
            formats={quillFormats}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-outline-transparent"
          onClick={() => {
            toggleModal();
            // setLoading(false);
          }}
        >
          Cancel
        </Button>
        <Button
          className={`btn-shadow btn-multiple-state ${
            selectedLessonContentEditing === contentData.id &&
            progressStatus === 'loading'
              ? 'show-spinner'
              : ''
          }`}
          onClick={saveContent}
        >
          <span className="spinner d-inline-block">
            <span className="bounce1" />
            <span className="bounce2" />
            <span className="bounce3" />
          </span>
          <span className="label">Save</span>
        </Button>
        {(contentData.is_published === 0 || !contentData.is_published) && (
          <Button
            className={`btn-shadow btn-multiple-state ${
              selectedLessonContentEditing === contentData.id &&
              progressStatus === 'loading'
                ? 'show-spinner'
                : ''
            }`}
            onClick={saveAndPublish}
          >
            <span className="spinner d-inline-block">
              <span className="bounce1" />
              <span className="bounce2" />
              <span className="bounce3" />
            </span>
            <span className="label">Save and Publish</span>
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
const mapStateToProps = (state) => {
  return {
    selectedCourseId: state.courses.selectedCourseId,
    progressStatus: state.courses.status,
    selectedLessonEditing: state.courses.selectedLessonEditing,
    selectedLessonContentEditing: state.courses.selectedLessonContentEditing,
  };
};
const mapDispatchToProps = {
  updateLessonContent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditContentMessageModal);
