import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { map } from 'lodash';
import { normalize, SchemaArray } from 'normalizr';
import {
  DELETE_COURSE,
  DELETE_COURSE_SUCCESS,
  EDIT_LESSON_CONTENT_SUCCESS,
  GET_COURSE,
  GOT_COURSE,
  PUBLISH_COURSE,
  PUBLISH_COURSE_SUCCESS,
  UN_PUBLISH_COURSE,
  UN_PUBLISH_COURSE_SUCCESS,
} from '../contants';

import CourseService from '../../services/CourseService';
import { adminRoot } from '../../constants/defaultValues';
import { RouteNames } from '../../constants/menu';
import {
  publishCourseError,
  publishCourseSuccess,
  unPublishCourseError,
  unPublishCourseSuccess,
  getCourseError,
  deleteCourseSuccess,
  deleteCourseError,
} from './actions';
import lessonSchema from '../schemas/lesson';
import contentSchema from '../schemas/content';
import courseSchema from '../schemas/course';

function* unPublishCourseSaga(action) {
  try {
    const courseResponse = yield call(
      CourseService.unpublishCourse,
      action.payload
    );
    yield put({
      type: UN_PUBLISH_COURSE_SUCCESS,
      payload: {
        message: 'The course was unpublished',
        courseId: action.payload,
      },
    });
  } catch (err) {
    yield put(unPublishCourseError(err));
  }
}
function* publishCourseSaga(action) {
  try {
    const publishCourseResponse = yield call(
      CourseService.publishCourse,
      action.payload
    );
    if (!publishCourseResponse.data.activated) {
      yield put(publishCourseError(publishCourseResponse.data.errors));
    } else {
      yield put({
        type: PUBLISH_COURSE_SUCCESS,
        payload: {
          message: 'The course was published',
          courseId: action.payload,
        },
      });
    }
  } catch (err) {
    yield put(publishCourseError(err));
  }
}
function* getCourseSaga({ payload }) {
  const { history, courseId } = payload;
  try {
    const courseResponse = yield call(CourseService.getCourse, courseId);
    // ToDo подумать ещё над обработкой ответов от сервера и поменть экшен
    if (courseResponse.status === 204) {
      history.push(`${adminRoot}/${RouteNames.COURSES}`);
    }
    if (courseResponse.status === 200) {
      yield put({
        type: GOT_COURSE,
        payload: normalize(courseResponse.data.data, courseSchema),
      });
    }
  } catch (err) {
    yield put(getCourseError(err));
  }
}
function* deleteCourseSaga({ payload }) {
  const { history, courseId } = payload;
  try {
    yield call(CourseService.deleteCourses, courseId);
    yield put({
      type: DELETE_COURSE_SUCCESS,
      payload: { message: 'The course was deleted', courseId },
    });
    history.push(`${adminRoot}/${RouteNames.COURSES}`);
  } catch (err) {
    yield put(deleteCourseError(err));
  }
}
export function* watchGetCourse() {
  yield takeEvery(GET_COURSE, getCourseSaga);
}
export function* watchPublishCourse() {
  yield takeEvery(PUBLISH_COURSE, publishCourseSaga);
}
export function* watchUnPublishCourse() {
  yield takeEvery(UN_PUBLISH_COURSE, unPublishCourseSaga);
}
export function* watchDeleteCourse() {
  yield takeEvery(DELETE_COURSE, deleteCourseSaga);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetCourse),
    fork(watchPublishCourse),
    fork(watchUnPublishCourse),
    fork(watchDeleteCourse),
  ]);
}
