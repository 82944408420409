import { $api } from '../helpers/api';

export default class LessonContentService {
  static async createLessonContent(courseId, lessonId, contentType, data) {
    return $api.post(`/bots/${courseId}/lessons/${lessonId}/contents`, {
      type: contentType,
      ...data,
    });
  }

  static async deleteLessonContent(courseId, lessonId, contentId) {
    return $api.delete(
      `/bots/${courseId}/lessons/${lessonId}/contents/${contentId}`,
      {}
    );
  }

  static async updateLessonContent(courseId, lessonId, contentId, data) {
    return $api.put(
      `/bots/${courseId}/lessons/${lessonId}/contents/${contentId}`,
      {
        ...data,
      }
    );
  }
}
