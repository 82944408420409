import { adminRoot, UserRole } from './defaultValues';

export const RouteNames = {
  DASHBOARDS: 'dashboards',
  COURSES: 'courses',
  USERS: 'users',
  BROADCASTING: 'broadcasting',
  SETTINGS: 'settings',
  COURSES_LIST: 'list',
  COURSES_ADD_NEW: 'new-course',
  LESSON_ADD_NEW: 'new-lesson',
  COURSE_INFORMATION: 'information',
  COURSE_CURRICULUM: 'curriculum',
  COURSE_STUDENTS: 'students',
  COURSE_SETTINGS: 'settings',
  LESSON: 'lesson',
};

export const data = [
  {
    id: RouteNames.DASHBOARDS,
    icon: 'iconsminds-home',
    label: 'menu.dashboards',
    to: `${adminRoot}/${RouteNames.DASHBOARDS}`,
    roles: [UserRole.Admin],
  },
  {
    id: RouteNames.COURSES,
    icon: 'iconsminds-book',
    label: 'menu.courses',
    to: `${adminRoot}/${RouteNames.COURSES}`,
    // roles: [UserRole.Editor],
  },
  // {
  //   id: 'users',
  //   icon: 'iconsminds-students',
  //   label: 'menu.users',
  //   to: `${adminRoot}/${RouteNames.USERS}`,
  // },
  {
    id: 'broadcasting',
    icon: 'iconsminds-loudspeaker',
    label: 'menu.broadcasting',
    to: `${adminRoot}/${RouteNames.BROADCASTING}`,
  },
  {
    id: 'settings',
    icon: 'simple-icon-settings',
    label: 'menu.settings',
    to: `${adminRoot}/${RouteNames.SETTINGS}`,
  },
];

export const coursesSubListMenu = [
  {
    icon: 'simple-icon-briefcase',
    label: 'menu.courses.list',
    to: `/${RouteNames.COURSES_LIST}`,
    // roles: [UserRole.Editor],
  },
  {
    icon: 'simple-icon-plus',
    label: 'menu.courses.new',
    to: `/${RouteNames.COURSES_ADD_NEW}`,
    // roles: [UserRole.Editor],
  },
];

export const singleCourseSubListMenu = [
  {
    name: RouteNames.COURSE_INFORMATION,
    icon: 'simple-icon-briefcase',
    label: 'menu.courses.information',
    to: `/${RouteNames.COURSE_INFORMATION}`,
    // roles: [UserRole.Admin],
  },
  {
    name: RouteNames.COURSE_CURRICULUM,
    icon: 'simple-icon-pie-chart',
    label: 'menu.courses.curriculum',
    to: `/${RouteNames.COURSE_CURRICULUM}`,
    // roles: [UserRole.Admin],
  },
  {
    name: RouteNames.COURSE_STUDENTS,
    icon: 'simple-icon-plus',
    label: 'menu.courses.students',
    to: `/${RouteNames.COURSE_STUDENTS}`,
    // roles: [UserRole.Editor],
  },
  {
    name: RouteNames.COURSE_SETTINGS,
    icon: 'simple-icon-basket-loaded',
    label: 'menu.courses.settings',
    to: `/${RouteNames.COURSE_SETTINGS}`,
    // roles: [UserRole.Editor],
  },
];
