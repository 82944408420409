// eslint-disable-next-line import/named
import Immutable from 'seamless-immutable';
import dotProp from 'dot-prop-immutable';

import {
  ADD_COURSE_SUCCESS,
  ADD_LESSON_CONTENT_SUCCESS,
  ADD_LESSON_SUCCESS,
  DELETE_COURSE_SUCCESS,
  DELETE_LESSON_CONTENT_SUCCESS,
  DELETE_LESSON_SUCCESS,
  EDIT_COURSE_SUCCESS,
  EDIT_LESSON_CONTENT_SUCCESS,
  EDIT_LESSON_SUCCESS,
  GET_COURSES_SUCCESS,
  PUBLISH_COURSE_SUCCESS,
  UN_PUBLISH_COURSE_SUCCESS,
} from '../contants';

// const INIT_STATE = {};
const INIT_STATE = Immutable({
  contents: [],
  lessons: [],
  courses: [],
});

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COURSES_SUCCESS:
      return {
        ...state,
        ...action.payload.entities,
      };
    case UN_PUBLISH_COURSE_SUCCESS:
      return dotProp.set(
        state,
        `courses.${action.payload.courseId}.webhook`,
        0
      );
    case PUBLISH_COURSE_SUCCESS:
      return dotProp.set(
        state,
        `courses.${action.payload.courseId}.webhook`,
        1
      );
    case ADD_COURSE_SUCCESS:
      return dotProp.set(
        state,
        `courses`,
        Immutable.merge(
          state.courses,
          action.payload.courseData.entities.courses
        )
      );
    case EDIT_COURSE_SUCCESS:
      return dotProp.set(
        state,
        `courses.${action.payload.courseId}`,
        Immutable.merge(
          state.courses[action.payload.courseId],
          action.payload.courseData.entities.courses[action.payload.courseId]
        )
      );
    case DELETE_COURSE_SUCCESS:
      return dotProp.delete(state, `courses.${action.payload.courseId}`);
    case ADD_LESSON_SUCCESS:
      const { courseId, lessonData } = action.payload;
      const lessonId = lessonData.result;
      const addWithCourseState = dotProp.set(
        state,
        `courses.${courseId}.lessons`,
        (lessons) => lessons.concat(lessonId)
      );

      return dotProp.set(
        addWithCourseState,
        `lessons`,
        Immutable.merge(state.lessons, lessonData.entities.lessons)
      );
    case EDIT_LESSON_SUCCESS:
      return dotProp.set(
        state,
        `lessons.${action.payload.lessonId}`,
        Immutable.merge(
          state.lessons[action.payload.lessonId],
          action.payload.lessonData.entities.lessons[action.payload.lessonId]
        )
      );
    case DELETE_LESSON_SUCCESS:
      const deleteWithCourseState = dotProp.set(
        state,
        `courses.${action.payload.courseId}.lessons`,
        (lessons) => lessons.filter((item) => item !== action.payload.lessonId)
      );
      return dotProp.delete(
        deleteWithCourseState,
        `lessons.${action.payload.lessonId}`
      );
    case ADD_LESSON_CONTENT_SUCCESS:
      const addContentWithLessonState = dotProp.set(
        state,
        `lessons.${action.payload.lessonId}.contents`,
        (contents) => contents.concat(action.payload.lessonContentData.result)
      );
      return dotProp.set(
        addContentWithLessonState,
        `contents`,
        Immutable.merge(
          state.contents,
          action.payload.lessonContentData.entities.contents
        )
      );
    case EDIT_LESSON_CONTENT_SUCCESS:
      return dotProp.set(
        state,
        `contents.${action.payload.contentId}`,
        Immutable.merge(
          state.contents[action.payload.contentId],
          action.payload.contentData.entities.contents[action.payload.contentId]
        )
      );
    case DELETE_LESSON_CONTENT_SUCCESS:
      const deleteContentWithLessonsState = dotProp.set(
        state,
        `lessons.${action.payload.lessonId}.contents`,
        (contents) =>
          contents.filter((item) => item !== action.payload.contentId)
      );
      return dotProp.delete(
        deleteContentWithLessonsState,
        `contents.${action.payload.contentId}`
      );
    default:
      return state;
  }
};
