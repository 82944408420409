import React, { useEffect, useState } from 'react';
import { Card, Row } from 'reactstrap';
import { Colxx } from 'components/common/CustomBootstrap';
import ReactQuill from 'react-quill';
import { connect, useStore } from 'react-redux';
import { injectIntl } from 'react-intl';
import EditContentMessageControl from '../contents/EditContentMessageControl';
import RemoveContentButton from '../contents/RemoveContentButton';
import { updateLessonContent } from '../../redux/courses/actions';
import DuplicateContentButton from '../contents/DuplicateContentButton';

const LessonContentFull = ({
  contentData,
  progressStatus,
  selectedLessonContentEditing,
  selectedCourseId,
  updateLessonContent,
}) => {
  const publishLessonContent = () => {
    if (progressStatus === 'loading') return false;
    updateLessonContent(
      selectedCourseId,
      contentData.lesson_id,
      contentData.id,
      { is_published: 1 }
    );
  };
  const unPublishLessonContent = () => {
    if (progressStatus === 'loading') return false;
    updateLessonContent(
      selectedCourseId,
      contentData.lesson_id,
      contentData.id,
      { is_published: 0 }
    );
  };
  return (
    <div key={contentData.id}>
      <Row className="mb-3">
        <Colxx xxs="12">
          <div
            className={`lesson-content-type-card-full-item is-type-${contentData.type}`}
          >
            <div className="lesson-content-type-card-full-item-side-column">
              <div className="lesson-content-type-card-full-item__type">
                {contentData.type}
              </div>
            </div>
            <div className="lesson-content-type-card-full-item-side-content">
              {progressStatus === 'loading' &&
                contentData.id === selectedLessonContentEditing && (
                  <div className="lesson-content-type-card-full-item-side-content--is-loading">
                    <div className="loading-icon" />
                  </div>
                )}
              <div className="lesson-content-type-card-full-item-drag">
                <div className="lesson-content-type-card-item__icon">
                  <div className="glyph-icon simple-icon-grid logo-drag" />
                </div>
              </div>
              <div className="lesson-content-type-card-full-item-main">
                <div className="lesson-content-type-card-full-item-head">
                  <div className="lesson-content-type-card-full-item__name">
                    {contentData.name}
                  </div>

                  {contentData.is_published === 0 ||
                  !contentData.is_published ? (
                    <>
                      <div className="lesson-content-type-card-full-item__status badge badge-light">
                        Is draft
                      </div>
                      <button
                        className="btn btn-primary btn-xs"
                        onClick={publishLessonContent}
                      >
                        Publish
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="lesson-content-type-card-full-item__status badge badge-light">
                        Is published
                      </div>
                      <button
                        className="btn btn-xs btn-outline-danger"
                        onClick={unPublishLessonContent}
                      >
                        Unpublish
                      </button>
                    </>
                  )}
                </div>
                <div className="lesson-content-type-card-full-item-bottom">
                  <div className="lesson-content-type-card-full-item-content">
                    <ReactQuill
                      value={contentData.content}
                      readOnly
                      theme="bubble"
                    />
                  </div>
                  <div className="lesson-content-type-card-full-item-controls">
                    <EditContentMessageControl contentData={contentData} />
                    <DuplicateContentButton contentDataCopyFrom={contentData} />
                    <RemoveContentButton
                      lessonId={contentData.lesson_id}
                      contentId={contentData.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Colxx>
      </Row>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    selectedCourseId: state.courses.selectedCourseId,
    progressStatus: state.courses.status,
    selectedLessonContentEditing: state.courses.selectedLessonContentEditing,
  };
};

const mapDispatchToProps = {
  updateLessonContent,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LessonContentFull));
