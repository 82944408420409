import { RESET_NOTIFICATION } from '../contants';

const INIT_STATE = {
  error: '',
  isOpen: false,
  message: '',
};

export default (state = INIT_STATE, action) => {
  if (action.type === RESET_NOTIFICATION) {
    return {
      ...state,
      error: null,
      isOpen: false,
      message: null,
    };
  }
  if (action.payload) {
    if (action.payload.error) {
      return {
        ...state,
        error: action.payload.error,
        isOpen: true,
      };
    }
    if (action.payload.message) {
      return {
        ...state,
        message: action.payload.message,
        isOpen: true,
      };
    }
  }
  return state;
};
