import React from 'react';

import IntlMessages from 'helpers/IntlMessages';

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { connect } from 'react-redux';
import { deleteLesson, deleteLessonContent } from '../../redux/courses/actions';

const RemoveContentButton = ({
  lessonId,
  contentId,
  selectedCourseId,
  deleteLessonContentAction,
}) => {
  const removeLessonContent = () => {
    deleteLessonContentAction(selectedCourseId, lessonId, contentId);
  };
  return (
    <button type="button" className="btn" onClick={() => removeLessonContent()}>
      <span className="simple-icon-trash" />
    </button>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedCourseId: state.courses.selectedCourseId,
  };
};

const mapDispatchToProps = {
  deleteLessonContentAction: deleteLessonContent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveContentButton);
