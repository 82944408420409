import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { normalize, schema } from 'normalizr';
import {
  ADD_COURSE,
  ADD_COURSE_ERROR,
  ADD_COURSE_SUCCESS,
  ADD_LESSON,
  ADD_LESSON_CONTENT,
  ADD_LESSON_CONTENT_ERROR,
  ADD_LESSON_CONTENT_SUCCESS,
  ADD_LESSON_ERROR,
  ADD_LESSON_SUCCESS,
  ADD_NEW_LESSON,
  DELETE_LESSON,
  DELETE_LESSON_CONTENT,
  DELETE_LESSON_CONTENT_ERROR,
  DELETE_LESSON_CONTENT_SUCCESS,
  DELETE_LESSON_ERROR,
  DELETE_LESSON_SUCCESS,
  EDIT_COURSE,
  EDIT_COURSE_ERROR,
  EDIT_COURSE_SUCCESS,
  EDIT_LESSON,
  EDIT_LESSON_CONTENT,
  EDIT_LESSON_CONTENT_ERROR,
  EDIT_LESSON_CONTENT_SUCCESS,
  EDIT_LESSON_ERROR,
  EDIT_LESSON_SUCCESS,
  GET_COURSES,
  GET_COURSES_LIST,
  GET_COURSES_SUCCESS,
  GOT_COURSES_LIST,
} from '../contants';

import CourseService from '../../services/CourseService';
import courseSchema from '../schemas/course';
import LessonService from '../../services/LessonService';
import lessonSchema from '../schemas/lesson';
import LessonContentService from '../../services/LessonContentService';
import contentSchema from '../schemas/content';
import singleCourseSchema from '../schemas/courseSchema';

export function* watchGetCourses() {
  yield takeEvery(GET_COURSES, getCoursesSaga);
}
export function* watchAddCourses() {
  yield takeEvery(ADD_COURSE, addCourse);
}
export function* watchEditCourse() {
  yield takeEvery(EDIT_COURSE, editCourse);
}
export function* watchAddLesson() {
  yield takeEvery(ADD_LESSON, addLesson);
}
export function* watchEditLesson() {
  yield takeEvery(EDIT_LESSON, editLesson);
}
export function* watchDeleteLesson() {
  yield takeEvery(DELETE_LESSON, deleteLesson);
}
export function* watchAddLessonContent() {
  yield takeEvery(ADD_LESSON_CONTENT, addLessonContent);
}
export function* watchEditLessonContent() {
  yield takeEvery(EDIT_LESSON_CONTENT, editLessonContent);
}
export function* watchDeleteLessonContent() {
  yield takeEvery(DELETE_LESSON_CONTENT, deleteLessonContent);
}

function* getCoursesSaga() {
  try {
    const coursesResponse = yield call(CourseService.getCourses);
    const data = normalize(coursesResponse.data.data, courseSchema);
    yield put({ type: GET_COURSES_SUCCESS, payload: data });
  } catch (err) {
    console.log(err);
  }
}

function* addLesson({ payload }) {
  const { courseId, dayType } = payload;
  try {
    const lessonCreateResponse = yield call(
      LessonService.createLesson,
      courseId,
      dayType
    );
    const lessonData = normalize(lessonCreateResponse.data.data, lessonSchema);
    yield put({
      type: ADD_LESSON_SUCCESS,
      payload: { courseId, lessonData, message: 'The new lesson added' },
    });
  } catch (err) {
    yield put({ type: ADD_LESSON_ERROR, payload: { courseId } });
  }
}

function* addCourse({ payload }) {
  const { name, token } = payload;
  try {
    const courseCreateResponse = yield call(
      CourseService.createCourse,
      name,
      token
    );
    const courseData = normalize(
      courseCreateResponse.data.data,
      singleCourseSchema
    );
    yield put({
      type: ADD_COURSE_SUCCESS,
      payload: { courseData, message: 'The new course added' },
    });
  } catch (err) {
    yield put({ type: ADD_COURSE_ERROR });
  }
}

function* editCourse({ payload }) {
  const { courseId, data } = payload;
  try {
    const updateCourseResponse = yield call(
      CourseService.updateCourse,
      courseId,
      data
    );
    const courseData = normalize(
      updateCourseResponse.data.data,
      singleCourseSchema
    );
    yield put({
      type: EDIT_COURSE_SUCCESS,
      payload: {
        courseId,
        courseData,
        message: 'The course settings has been successfully updated',
      },
    });
  } catch (err) {
    yield put({
      type: EDIT_COURSE_ERROR,
      payload: { courseId, error: "Course settings wasn't updated" },
    });
  }
}

function* editLesson({ payload }) {
  const { courseId, lessonId, data } = payload;
  try {
    const lessonEditResponse = yield call(
      LessonService.updateLesson,
      courseId,
      lessonId,
      data
    );
    const lessonData = normalize(lessonEditResponse.data.data, lessonSchema);
    yield put({
      type: EDIT_LESSON_SUCCESS,
      payload: {
        courseId,
        lessonId,
        lessonData,
        message: 'The lesson updated',
      },
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: EDIT_LESSON_ERROR,
      payload: { courseId, lessonId, error: "The lesson wasn't updated" },
    });
  }
}

function* deleteLesson({ payload }) {
  const { courseId, lessonId } = payload;
  try {
    const lessonDeleteResponse = yield call(
      LessonService.deleteLesson,
      courseId,
      lessonId
    );
    yield put({
      type: DELETE_LESSON_SUCCESS,
      payload: { courseId, lessonId, message: 'The lesson deleted' },
    });
  } catch (err) {
    yield put({ type: DELETE_LESSON_ERROR, payload: { courseId, lessonId } });
  }
}

function* addLessonContent({ payload }) {
  const { courseId, lessonId, contentType, data } = payload;
  try {
    const lessonContentCreateResponse = yield call(
      LessonContentService.createLessonContent,
      courseId,
      lessonId,
      contentType,
      data
    );
    const lessonContentData = normalize(
      lessonContentCreateResponse.data.data,
      contentSchema
    );
    yield put({
      type: ADD_LESSON_CONTENT_SUCCESS,
      payload: {
        courseId,
        lessonId,
        lessonContentData,
        message: 'Lesson content added',
      },
    });
  } catch (err) {
    yield put({ type: ADD_LESSON_CONTENT_ERROR, payload: data });
  }
}

function* editLessonContent({ payload }) {
  const { courseId, lessonId, contentId, data } = payload;
  try {
    const lessonContentUpdateResponse = yield call(
      LessonContentService.updateLessonContent,
      courseId,
      lessonId,
      contentId,
      data
    );

    const contentData = normalize(
      lessonContentUpdateResponse.data.data,
      contentSchema
    );
    yield put({
      type: EDIT_LESSON_CONTENT_SUCCESS,
      payload: {
        courseId,
        lessonId,
        contentId,
        contentData,
        message: 'The lesson content updated',
      },
    });
  } catch (err) {
    console.log(err);
    yield put({ type: EDIT_LESSON_CONTENT_ERROR, payload: data });
  }
}

function* deleteLessonContent({ payload }) {
  const { courseId, lessonId, contentId } = payload;
  try {
    const { lessonContentDeleteResponse, statusCode } = yield call(
      LessonContentService.deleteLessonContent,
      courseId,
      lessonId,
      contentId
    );
    yield put({
      type: DELETE_LESSON_CONTENT_SUCCESS,
      payload: {
        courseId,
        lessonId,
        contentId,
        message: 'The lesson content deleted',
      },
    });
  } catch (err) {
    yield put({ type: DELETE_LESSON_CONTENT_ERROR });
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetCourses),
    fork(watchAddCourses),
    fork(watchEditCourse),
    fork(watchAddLesson),
    fork(watchDeleteLesson),
    fork(watchEditLesson),
    fork(watchAddLessonContent),
    fork(watchDeleteLessonContent),
    fork(watchEditLessonContent),
  ]);
}
