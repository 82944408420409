import React from 'react';
import { Row, UncontrolledAlert } from 'reactstrap';
import { Colxx } from 'components/common/CustomBootstrap';

const Info = ({ children }) => {
  return (
    <Row className="mb-4">
      <Colxx xxs="12" className="mb-4">
        <UncontrolledAlert color="info" fade={false}>
          <div
            className="glyph-icon simple-icon-question logo-question mr-2"
            id="tooltip_bottom"
          />
          {children}
        </UncontrolledAlert>
      </Colxx>
    </Row>
  );
};

export default React.memo(Info);
