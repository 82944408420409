import { schema } from 'normalizr';

const contentSchema = new schema.Entity(
  'contents',
  {},
  {
    processStrategy: (value, parent, key) => {
      return { ...value, lesson: parent.id };
    },
  }
);

export default contentSchema;
